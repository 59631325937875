<template>
	<div class="personal">
		<top-search @changeMode="changeMode"></top-search>
		<div v-loading="loading" class="personal_info">
			<div class="left_info">
				<img class="headImg" :src="$img(member_info.headimg ? member_info.headimg : defaultHeadImage )" @error="member_info.headimg = defaultHeadImage" />
				<div class="real_name">{{member_info.realname ? member_info.realname : member_info.nickname }}</div>
				<div class="agent" v-if="member_info.is_agent">
					<img class="is_agent" src="@/assets/images/newImg/isAgent.png" />
					经纪人</div>
				<div class="member_tit">主页</div>
			</div>
			<div class="area_info">
				<div class="tit">领域</div>
				<div class="area_list">
					<div class="area_item" :class="area_active==''?'active':''" @click="changeCategory('')">全部</div>
					<div class="area_item" :class="area_active==item.category_id?'active':'' " v-for="(item,index) in category_list" :key="index" @click="changeCategory(item.category_id)">{{item.category_name}}</div>
				</div>
			</div>
		</div>
		<div class="modes">
			<div class="mode_item" @click="changeGoodsClass(5)" :class="goods_class==5?'active':''">资源<div class="line"></div></div>
			<div class="mode_item" @click="changeGoodsClass(6)" :class="goods_class==6?'active':''">需求<div class="line"></div></div>
		</div>
		<div class="goods_list" v-loading="goods_loading">
			<template v-if="goods_class==5">
				<div class="goods_item" v-for="(item,index) in goods_list" :key="index" @click="toGoodsDetail(item.goods_id)">
					<img class="goods_img" :src="$img(item.goods_image.split(',')[0],{ size: 'mid' })" alt="" @error="item.goods_image = defaultGoodsImage">
					<div class="goods_info">
						<div class="left">
							<div class="goods_tit">{{item.goods_name}}</div>
							<div class="goods_tag">
								<div class="tag" v-if="item.agent_member_id != 0">官验</div>
								<div class="tag">{{item.category_name}}</div>
								<div class="tag" v-for="(aItem,aIndex) in item.goods_attr_parse" :key="aIndex" v-if="aItem.label_type == 1">{{aItem.attr_value_name}}</div>
							</div>
							<div class="accredits">
								<div class="item" v-for="(aItem,aIndex) in item.goods_attr_parse" :key="aIndex" v-if="aItem.label_type == 2">{{aItem.attr_value_name}}</div>
							</div>
						</div>
						<div class="right">
							<div class="price">￥<div class="num">{{item.price}}</div></div>
							<div class="row first">
								<div class="time">{{$util.timeStampTurnTime(item.create_time).split(' ')[0].replaceAll('-','/')}}</div>
								<div class="kefu" @click.stop="toViewChat(item.member_info.member_id)">客服</div>
							</div>
							<div class="row">
								<div class="info">
									<img class="logo" v-if="item.member_info" :src="$img(item.member_info.headimg)" @error="item.member_info.headimg = defaultHeadImage" alt="">
									<div class="iconfont1 icon-xihuan" :class="item.is_collect?'active':''" @click.stop="collectGoods(item)"></div>
									<img src="@/assets/images/newImg/message.png" @click.stop="evaluateGoods(item,'comment')" />
									<img src="@/assets/images/newImg/bargain.png" @click.stop="evaluateGoods(item,'bargain')" />
									<div class="iconfont1 icon-gouwuche purchase" :class="item.is_cart?'active':''" @click.stop="purchaseGoods(item)"></div>
								</div>
								<div class="kefu wx">微信
									<img v-if="item.member_info.wechat_personal_qrcode" :src="$img(item.member_info.wechat_personal_qrcode)" alt="">
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
			<template v-else>
				<div class="goods_item demand" v-for="(item,index) in goods_list" :key="index" @click="toGoodsDetail(item.goods_id)">
					<div class="goods_info">
						<div class="left">
							<div class="goods_tit">{{item.goods_name}}</div>
							<div class="goods_tag">
								<div class="tag" v-if="item.agent_member_id != 0">官验</div>
								<div class="tag">{{item.category_name}}</div>
								<div class="tag" v-for="(aItem,aIndex) in item.goods_attr_parse" :key="aIndex" v-if="aItem.label_type == 1">{{aItem.attr_value_name}}</div>
							</div>
							<div class="accredits">
								<div class="item" v-for="(aItem,aIndex) in item.goods_attr_parse" :key="aIndex" v-if="aItem.label_type == 2">{{aItem.attr_value_name}}</div>
							</div>
						</div>
						<div class="right">
							<div class="price">￥<div class="num">{{item.price}}</div></div>
							<div class="row first">
								<div class="time">{{$util.timeStampTurnTime(item.create_time).split(' ')[0].replaceAll('-','/')}}</div>
								<div class="kefu" @click.stop="toViewChat(item.member_info.member_id)">客服</div>
							</div>
							<div class="row">
								<div class="info">
									<img class="logo" v-if="item.member_info" :src="$img(item.member_info.headimg)" @error="item.member_info.headimg = defaultHeadImage" alt="">
									<!-- <div class="iconfont1 icon-xihuan" :class="item.is_collect?'active':''" @click.stop="collectGoods(item)"></div> -->
									<img src="@/assets/images/newImg/message.png" @click.stop="evaluateGoods(item,'comment')" />
									<img src="@/assets/images/newImg/invitation.png" @click.stop="evaluateGoods(item,'tender')" />
									<img class="last" src="@/assets/images/newImg/contact.png" />
								</div>
								<div class="kefu wx">微信
									<img v-if="item.member_info.wechat_personal_qrcode" :src="$img(item.member_info.wechat_personal_qrcode)" alt="">
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
		</div>
		<div class="pager">
			<el-pagination 
				background 
				:pager-count="5" 
				:total="total" 
				layout="prev,pager,next,jumper,total"
				:current-page.sync="currentPage" 
				:page-size.sync="pageSize" 
				@size-change="handlePageSizeChange" 
				@current-change="handleCurrentPageChange" 
				hide-on-single-page
			></el-pagination>
		</div>
		<servicerMessage ref="servicerMessage" class="kefu" :toUid="toUid"></servicerMessage>
	</div>
</template>

<script>
	import { sellerInfo, sellerCategory, goodsList, deleteCollect, addCollect, deleteCart, addCart } from '@/api/resource'
	import topSearch from '@/components/top_search';
	import servicerMessage from "@/components/message/servicerMessage"
	import { mapGetters } from "vuex"
	export default {
		data() {
			return {
				current_mode: 1,
				member_info:{},
				member_id:'',
				loading:true,
				area_active:'',
				goods_class:5,
				category_list:[],
				total:0,
				currentPage: 1,
				pageSize: 10,
				goods_loading:true,
				goods_list:[],
				toUid:'',
			}
		},
		components: {
			topSearch,
			servicerMessage
		},
		computed: {
		    ...mapGetters(["token", "defaultHeadImage", "defaultShopImage", "defaultGoodsImage", "addonIsExit", "locationRegion","member"])
		},
		created() {
			this.member_id = this.$route.params.pathMatch;
			this.getMemberInfo();
			this.getSellerCategory();
			this.getGoodsList();
			
		},
		mounted(){
			if(localStorage.getItem('isKefuPersonal') && this.token){
				this.toUid = 'member_' + localStorage.getItem('isKefuPersonal');
				localStorage.removeItem('isKefuPersonal');
				this.$refs.servicerMessage.show();
			}
		},
		methods: {
			toViewChat(id=''){
				if(!this.token){
					this.$message.warning('请先进行登录');
					localStorage.setItem("isKefuPersonal",id);
					setTimeout(()=>{
						this.$router.push({path: '/login', query: {redirect: "/personal-"+this.member_id}});
					},800)
					return;
				}
				if(id){
					this.toUid = 'member_'+id;
				}else{
					this.toUid = 'member_'+this.goods_info.member_info.member_id;
				}
				this.$refs.servicerMessage.show();
			},
			evaluateGoods(goodsItem,mode){
				if(this.goods_class==5){
					this.$router.pushToTab('/resource-'+goodsItem.goods_id+'?status='+mode)
				}else{
					this.$router.pushToTab('/demand-'+goodsInfo.goods_id+'?status='+mode)
				}
			},
			purchaseGoods(info){
				if(!this.token){
					this.$message.error('请先进行登录');
					setTimeout(()=>{
						this.$router.push({path: '/login', query: {redirect: "/personal-"+this.member_id}});
					},800)
					return;
				}
				if(info.is_cart){
					deleteCart({
						goods_id:info.goods_id
					})
					.then(res=>{
						if(res.code>=0){
							info.is_cart = 0;
						}else{
							this.$message.error(res.message)
						}
					})
					.catch(err=>{
						this.$message.error(err.message)
					})
				}else{
					addCart({
						goods_id:info.goods_id
					})
					.then(res=>{
						if(res.code>=0){
							info.is_cart = 1;
						}else{
							this.$message.error(res.message)
						}
					})
					.catch(err=>{
						this.$message.error(err.message)
					})
				}
			},
			toGoodsDetail(goodsId){
				if(this.goods_class==5){
					this.$router.pushToTab('/resource-'+goodsId)
				}else{
					this.$router.pushToTab('/demand-'+goodsId)
				}
			},
			collectGoods(info){
				if(!this.token){
					this.$message.error('请先进行登录');
					setTimeout(()=>{
						this.$router.push({path: '/login', query: {redirect: "/personal-"+this.member_id}});
					},800)
					return;
				}
				if(info.is_collect){
					deleteCollect({
						goods_id:info.goods_id
					})
					.then(res=>{
						if(res.code>=0){
							info.is_collect = 0;
						}else{
							this.$message.error(res.message)
						}
					})
					.catch(err=>{
						this.$message.error(err.message)
					})
				}else{
					addCollect({
						goods_id:info.goods_id
					})
					.then(res=>{
						if(res.code>=0){
							info.is_collect = 1;
						}else{
							this.$message.error(res.message)
						}
					})
					.catch(err=>{
						this.$message.error(err.message)
					})
				}
			},
			handlePageSizeChange(size) {
				this.pageSize = size;
				this.goods_loading = true;
				this.getGoodsList();
			},
			handleCurrentPageChange(page) {
				this.currentPage = page;
				this.goods_loading = true;
				this.getGoodsList();
			},
			changeGoodsClass(mode){
				this.area_active = '';
				this.goods_class = mode;
				this.goods_loading = true;
				this.getSellerCategory();
				this.getGoodsList();
			},
			getGoodsList(){
				goodsList({
					member_id:this.member_id,
					goods_class:this.goods_class,
					page:this.currentPage,
					page_size:this.pageSize,
					category_id:this.area_active,
				})
				.then(res=>{
					if(res.code>=0){
						this.total = res.data.count;
						this.goods_list = this.$util.deepClone(res.data.list);
						this.goods_list.forEach(item=>{
							if(item.goods_attr_format){
								this.$set(item,'goods_attr_parse',JSON.parse(item.goods_attr_format))
							}
						})
						this.goods_loading = false;
					}
				})
			},
			changeCategory(id){
				this.area_active = id;
				this.goods_loading = true;
				this.getGoodsList();
			},
			getSellerCategory(){
				sellerCategory({
					member_id:this.member_id,
					goods_class:this.goods_class,
				})
				.then(res=>{
					if(res.code>=0){
						this.category_list = res.data;
					}
				})
			},
			getMemberInfo(){
				sellerInfo({
					member_id:this.member_id
				})
				.then(res=>{
					if(res.code>=0){
						this.member_info = res.data;
						this.loading = false;
					}
				})
			},
			changeMode(mode) {
			    this.current_mode = mode;
			},
		},
	}
</script>
<style lang="scss">
	.personal{
		.pager{
			margin-top: 30px;
			.el-pagination{
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				.btn-prev{
					margin-right: 15px !important;
				}
				.btn-next{
					margin-left: 15px !important;
				}
				.btn-prev,.btn-next{
					min-width: 20px;
					height: 20px;
					margin: 0;
					color: #30373D !important;
					font-weight: bolder !important;
					background-color: transparent;
				}
				.el-pagination__jump{
					margin-left: 20px;
					color: #86909C;
					font-size: 14px;
					line-height: 11px;
					display: flex;
					align-items: center;
					height: auto;
					.el-pagination__editor{
						margin: 0 8px;
						padding: 0;
						height: auto;
						width: auto;
						.el-input__inner{
							height:auto;
							min-width: auto;
							border: none;
							color: #30373D;
							padding: 3px 2px 2px;
							line-height: 14px;
							background-color: #F6F7FA;
							font-size: 14px;
						}
					}
				}
				.el-pagination__total{
					margin-left: 10px;
					color: #86909C;
					font-size: 14px;
					height: 21px;
					line-height: 21px;
				}
				.el-pager{
					display: flex;
					align-items: center;
					.btn-quicknext,.btn-quickprev{
						height: 21px;
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: transparent;
					}
					.number{
						padding: 0;
						color: #30373D;
						line-height: 11px;
						height: auto;
						min-width: auto;
						padding: 5px 6px;
						font-size: 14px;
						margin: 0;
						font-weight: 500;
						margin-right: 10px;
						background-color: transparent;
						&:last-child{
							margin-right: 0;
						}
						&:not(.disabled).active{
							color: #30373D;
							font-weight: bolder;
							background-color: #F6F7FA;
							border-radius: 1px;
						}
					}
				}
			}
		}
	}
</style>
<style lang="scss" scoped>
	.personal{
		padding-top: 30px;
		.personal_info{
			display: flex;
			align-items: stretch;
			min-height: 273px;
			margin-top: 30px;
			.left_info{
				box-sizing: border-box;
				width: 286px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				background-color: #fff;
				margin-right: 20px;
				border-radius: 12px;
				padding: 0 30px;
				box-shadow: 0px 25px 56px 0px rgba(240,237,246,0.54);
				.member_tit{
					margin-top: 30px;
					color: #30373D;
					font-size: 13px;
					line-height: 14px;
					text-align: center;
					cursor: pointer;
					font-weight: 600;
				}
				.headImg{
					width: 61px;
					height: 61px;
					display: block;
					margin-bottom: 10px;
					border-radius: 50%;
					object-fit: cover;
				}
				.real_name{
					color: #30373D;
					font-size: 19px;
					line-height: 39px;
					margin-bottom: 3px;
				}
				.agent{
					color: #30373D;
					font-size: 13px;
					line-height: 14px;
					display: flex;
					align-items: center;
					.is_agent{
						width:11px;
						height:11px;
						display:block;
						margin-right: 6px;
						margin-bottom: 0;
					}
				}
			}
			.area_info{
				box-sizing: border-box;
				border-radius: 10px;
				flex: 1;
				padding: 20px;
				background-color: #fff;
				display: flex;
				flex-direction: column;
				box-shadow: 0px 25px 56px 0px rgba(240,237,246,0.54);
				.tit{
					color: #222B33;
					font-size: 19px;
					line-height: 27px;
					font-weight: 600;
					margin-bottom: 18px;
				}
				.area_list{
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					.area_item{
						color: #30373D;
						background-color: #F7F8FB;
						border-radius: 5px;
						margin-right: 20px;
						width: calc( ( 100% - 140px ) / 8 );
						text-align: center;
						padding: 10px 0;
						font-size: 15px;
						line-height: 21px;
						margin-top: 30px;
						cursor: pointer;
						&:nth-child(8n){
							margin-right: 0;
						}
						&.active{
							background-color: #30373D;
							color: #fff;
						}
					}
				}
			}
		}
	}
	.modes{
		display:flex;
		align-items: center;
		justify-content: center;
		margin-top: 30px;
		.mode_item{
			color: #30373D;
			font-size: 19px;
			line-height: 27px;
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-right: 58px;
			cursor: pointer;
			&:last-child{
				margin-right: 0;
			}
			.line{
				width: 34px;
				height: 3px;
				background-color: transparent;
				border-radius: 5px;
				margin-top: 9px;
			}
			&.active{
				color: #FF3300;
				.line{
					background-color: #FF3300;
				}
			}
		}
	}
	.goods_list{
		margin-top: 30px;
		.goods_item{
			cursor: pointer;
			margin-bottom: 30px;
			display: flex;
			border-radius: 10px;
			background-color: #fff;
			align-items: center;
			.goods_img{
				border-radius: 10px;
				width: 197px;
				height: 197px;
				display: block;
				margin-right: 30px;
			}
			.goods_info{
				flex: 1;
				display: flex;
				height: 197px;
				align-items: center;
				padding: 20px 22px 20px 0;
				box-sizing: border-box;
				.left{
					display: flex;
					flex-direction: column;
					justify-content: center;
					height: 100%;
					flex: 1;
					padding-right: 20px;
					.goods_tit{
						color: #30373D;
						font-size: 19px;
						line-height: 39px;
						font-weight: 600;
						margin-bottom: 10px;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
					}
					.goods_tag{
						display: flex;
						align-items: center;
						height: 22px;
						overflow: hidden;
						flex-wrap: wrap;
						.tag{
							color: #30373D;
							font-size: 15px;
							line-height: 15px;
							padding: 3px 9px 4px;
							background-color: rgba(48, 55, 61, 0.05);
							border-radius: 3px;
							margin-right: 10px;
							&:last-child{
								margin-right: 0;
							}
						}
					}
					.accredits{
						display: flex;
						align-items: center;
						flex-wrap: wrap;
						height: 64px;
						overflow: hidden;
						align-content: flex-start;
						.item{
							color: #30373D;
							font-size: 15px;
							line-height: 20px;
							padding: 0 4px;
							border: 1px solid rgba(48, 55, 61, 0.20);
							border-radius: 3px;
							margin-right: 10px;
							margin-top: 10px;
							box-sizing: border-box;
						}
					}
				}
				.right{
					height: 100%;
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
					align-items: flex-end;
					.price{
						display: flex;
						align-items: baseline;
						color: #FF3300;
						font-size: 13px;
						line-height: 39px;
						.num{
							font-size: 19px;
							font-weight: 600;
						}
					}
					.row{
						display: flex;
						align-items: flex-end;
						&.first{
							margin-bottom: 10px;
							margin-top: 27px;
						}
						.info{
							display: flex;
							align-items: center;
							img{
								width: 18px;
								height: 18px;
								display: block;
								margin-right: 20px;
							}
							.iconfont1{
								font-size: 18px;
								line-height: 1;
								margin-right: 20px;
								color: #999;
								&.active{
									color: #FF3300;
								}
							}
							.logo{
								border-radius: 50%;
								object-fit: cover;
							}
							.last{
								margin-right: 0;
							}
							.purchase{
								margin-right: 0;
								font-size: 15px !important;
								line-height: 15px !important;
							}
						}
						.time{
							color: #999;
							font-size: 13px;
							line-height: 18px;
						}
						.kefu{
							color: #fff;
							font-size: 13px;
							line-height: 18px;
							padding: 7px 40px;
							border-radius: 60px;
							background-color: #30373D;
							margin-left: 52px;
							position: relative;
							&.wx{
								color: #1BCF42;
								background-color: rgba(27, 207, 66, 0.20);
								&:hover{
									img{
										display: block;
									}
								}
							}
							img{
								position: absolute;
								top: 100%;
								left: 50%;
								transform: translateX(-50%);
								display: none;
							}
						}
					}
				}
			}
		}
	}
	.goods_item.demand{
		cursor: pointer;
		margin-bottom: 30px;
		display: flex;
		border-radius: 10px;
		background-color: #fff;
		align-items: center;
		padding: 0 0 0 22px;
		box-shadow: 0px 25px 56px 0px rgba(240,237,246,0.55);
		.goods_info{
			flex: 1;
			display: flex;
			align-items: center;
			.left{
				display: flex;
				flex-direction: column;
				justify-content: center;
				height: 100%;
				flex: 1;
				padding-right: 20px;
				.goods_tit{
					color: #30373D;
					font-size: 19px;
					line-height: 39px;
					font-weight: 600;
					margin-bottom: 10px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
				}
				.goods_tag{
					display: flex;
					align-items: center;
					height: 22px;
					overflow: hidden;
					flex-wrap: wrap;
					.tag{
						color: #30373D;
						font-size: 15px;
						line-height: 15px;
						padding: 3px 9px 4px;
						background-color: rgba(48, 55, 61, 0.05);
						border-radius: 3px;
						margin-right: 10px;
						&:last-child{
							margin-right: 0;
						}
					}
				}
				.accredits{
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					height: 64px;
					overflow: hidden;
					align-content: flex-start;
					.item{
						color: #30373D;
						font-size: 15px;
						line-height: 20px;
						padding: 0 4px;
						border: 1px solid rgba(48, 55, 61, 0.20);
						border-radius: 3px;
						margin-right: 10px;
						margin-top: 10px;
						box-sizing: border-box;
					}
				}
			}
			.right{
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				align-items: flex-end;
				.price{
					display: flex;
					align-items: baseline;
					color: #FF3300;
					font-size: 13px;
					line-height: 39px;
					.num{
						font-size: 19px;
						font-weight: 600;
					}
				}
				.row{
					display: flex;
					align-items: flex-end;
					&.first{
						margin-bottom: 10px;
						margin-top: 27px;
					}
					.info{
						display: flex;
						align-items: center;
						.purchase{
							margin-right: 0;
							font-size: 15px !important;
							line-height: 15px !important;
						}
						img{
							width: 18px;
							height: 18px;
							display: block;
							margin-right: 20px;
						}
						.iconfont1{
							font-size: 18px;
							line-height: 1;
							margin-right: 20px;
							color: #999;
							&.active{
								color: #FF3300;
							}
						}
						.logo{
							object-fit: cover;
							border-radius: 50%;
						}
						.last{
							margin-right: 0;
						}
					}
					.time{
						color: #999;
						font-size: 13px;
						line-height: 18px;
					}
					.kefu{
						color: #fff;
						font-size: 13px;
						line-height: 18px;
						padding: 7px 40px;
						border-radius: 60px;
						background-color: #30373D;
						margin-left: 52px;
						&.wx{
							color: #1BCF42;
							background-color: rgba(27, 207, 66, 0.20);
						}
					}
				}
			}
		}
	}
</style>